<template>
  <v-card height="100%">
    <div class="pa-5">
      <form>
        <h3>{{ $t("artworkReportReason") }}</h3>
        <v-radio-group v-model="reason">
          <v-radio value="Bug" :label="$t('artworkReportBug')"></v-radio>
          <v-radio value="Inappropriate" :label="$t('artworkReportInappropriate')"></v-radio>
          <v-radio value="Feedback" :label="$t('artworkReportFeedback')"></v-radio>
          <v-radio value="Other Reason" :label="$t('artworkReportOtherReason')"></v-radio>
        </v-radio-group>
        <div v-if="reason === 'Inappropriate'">
          <h5>{{ $t("artworkReportWhyInappropriate") }}</h5>
          <v-radio-group v-model="description">
            <v-radio value="Copyright Infringement" :label="$t('artworkReportCopyright')"></v-radio>
            <v-radio value="Plagarism" :label="$t('artworkReportPlagarized')"></v-radio>
            <v-radio value="Sexually explicit" :label="$t('artworkReportSexual')"></v-radio>
            <v-radio value="Violence" :label="$t('artworkReportViolent')"></v-radio>
            <v-radio value="Hate Speech" :label="$t('artworkReportHateSpeech')"></v-radio>
            <v-radio value="Fake Content" :label="$t('artworkReportFakeNews')"></v-radio>
            <v-radio value="Offensive" :label="$t('artworkReportOffensive')"></v-radio>
            <v-radio value="Others" :label="$t('artworkReportOtherInappropriate')"></v-radio>
          </v-radio-group>
        </div>
        <p>{{ $t("artworkReportComments") }}</p>
        <v-textarea outlined v-model="userComments"></v-textarea>
      </form>
      <div style="display: flex; justify-content: space-between">
        <v-btn
          width="30vw"
          max-width="150px"
          depressed
          color="primary"
          rounded
          :href="emailHref"
          @click="$emit('close')"
          ><b>{{ $t("artworkReportSend") }}</b></v-btn
        >
        <v-btn width="30vw" max-width="150px" depressed color="secondary" rounded @click="$emit('close')"
          ><b>{{ $t("profileBasicBack") }}</b></v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["workid"],
  data: () => ({
    reason: "",
    description: "",
    userComments: ""
  }),
  computed: {
    emailHref() {
      const description = this.reason === "Inappropriate" ? `Description: ${this.description},%0d%0a` : "";
      const body = `Reason: ${this.reason},%0d%0a${description}Comments: ${this.userComments}
        `;
      return `mailto:standbyu@tectechain.com?subject=Report/Feedback for work ${this.workid}&body=${body}`;
    }
  }
};
</script>

<style></style>
