<template>
  <div style="display: flex; align-items: center">
    <avatar-from-user-id :address="address" :size="avatarSize"></avatar-from-user-id>
    <span class="ma-0 ml-2" :style="textStyle">
      <user-id-from-address :address="address" />
    </span>
  </div>
</template>

<script>
import AvatarFromUserId from "./AvatarFromUserId.vue";
import UserIdFromAddress from "./userIdFromAddress.vue";
export default {
  components: {
    AvatarFromUserId,
    UserIdFromAddress
  },
  props: {
    address: {
      type: String
    },
    textStyle: {},
    avatarSize: {
      type: String,
      default: "16"
    }
  }
};
</script>

<style></style>
