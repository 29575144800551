
<template>
  <div class="platformCard pa-0">
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
      :allowCancel="messageBox.allowCancel"
    />
    <confirm-dlg ref="confirm" confirmBtn="dialogButton.publish" />
    <v-col cols="12" class="pa-0" v-if="!hideActions">
      <div class="timeIndicator" flat>
        <img src="@/assets/icon_pack/16px/clock.svg" height="20px" width="20px" alt="" />
        <p>{{ $t("artworkTimestamp", { date: getDate }) }}</p>
      </div>
    </v-col>
    <div v-if="!hideActions">
      <v-col cols="12" v-if="inMyCollection" id="leftRightControl">
        <v-btn text color="primary" @click="navigateWork('prev')" :disabled="workListIndex === 0" data-cy="prevWork"
          ><v-icon left> mdi-chevron-left </v-icon>{{ $t("artworkPrevious") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="navigateWork('next')"
          :disabled="workListIndex === workList.length - 1"
          data-cy="nextWork"
          >{{ $t("artworkNext") }} <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-else id="leftRightControl">
        <v-btn text color="primary" @click="moveToPrevWork()" :disabled="!prevWork" data-cy="prevWork"
          ><v-icon left> mdi-chevron-left </v-icon>{{ $t("artworkPrevious") }}
        </v-btn>
        <v-btn text color="primary" @click="moveToNextWork()" :disabled="!nextWork" data-cy="nextWork"
          >{{ $t("artworkNext") }} <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </v-col>
    </div>
    <div class="artworkDisplay artworkDisplayCard">
      <div class="imageViewer">
        <div v-if="displayItem.coverPhotos" style="width: 100%">
          <v-dialog content-class="elevation-0" v-model="modalWindow">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div>
                  <ImageFromFileDescriptor
                    :fileDescriptor="displayItem.coverPhotos[viewImageIndex]"
                  ></ImageFromFileDescriptor>
                </div>
              </div>
            </template>

            <v-btn
              icon
              fab
              @click="modalWindow = false"
              depressed
              dark
              data-cy="closeModal"
              style="position: absolute; top: 30px; right: 30px; z-index: 1"
            >
              <v-icon x-large>mdi-close</v-icon>
            </v-btn>
            <div style="max-height: 100vh; display: flex; align-items: center">
              <v-btn
                icon
                dark
                large
                :disabled="viewImageIndex === 0"
                @click="viewImageIndex--"
                style="position: fixed; bottom: 20px; left: 20px; z-index: 20"
              >
                <v-icon large>mdi-chevron-left</v-icon>
              </v-btn>
              <div style="flex: 1; overflow: hidden">
                <ImageFromFileDescriptor
                  :fileDescriptor="displayItem.coverPhotos[viewImageIndex]"
                  :borderRadius="false"
                  :thumbnail="false"
                  :contain="true"
                  height="80vh"
                  width="100vw"
                ></ImageFromFileDescriptor>
              </div>
              <v-btn
                icon
                large
                dark
                :disabled="viewImageIndex === displayItem.coverPhotos.length - 1"
                @click="viewImageIndex++"
                style="position: fixed; bottom: 20px; right: 20px; z-index: 20"
              >
                <v-icon large>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-dialog>
        </div>
        <div v-if="displayItem.coverPhotos.length > 1" style="width: 100%">
          <v-slide-group v-model="viewImageIndex" show-arrows class="my-4" mandatory light style="width: 100%">
            <v-slide-item v-for="i in displayItem.coverPhotos.length" :key="i" v-slot="{ active, toggle }">
              <v-expand-transition>
                <v-card
                  :class="i < displayItem.coverPhotos.length ? 'mr-2' : ''"
                  :color="active ? 'primary' : ''"
                  height="80"
                  width="80"
                  flat
                  @click="toggle"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 13px;
                    overflow: hidden;
                  "
                >
                  <ImageFromFileDescriptor
                    :fileDescriptor="displayItem.coverPhotos[i - 1]"
                    :key="displayItem.coverPhotos[i - 1].fileName"
                    :height="active ? '72' : '80'"
                    :width="active ? '72' : '80'"
                  ></ImageFromFileDescriptor>
                </v-card>
              </v-expand-transition>
            </v-slide-item>
          </v-slide-group>
        </div>
        <div class="timestamp hidden-xs-only" style="width: 100%">
          <img :src="timestampStickerLink" class="stamp3x" />
          <div class="timestampBarNormal">
            <div class="mb-2">
              <p>{{ $t("viewDate") }}</p>
              <h3>{{ getDate }}</h3>
            </div>
            <div>
              <p>{{ $t("viewTime") }}</p>
              <h3>{{ getTime }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div style="flex: 1; display: flex; flex-direction: column">
        <div class="artworkInfo">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 0px">
            <h1 style="font-size: 24px">
              <b v-if="!noTitle">{{ artworkTitle }}</b>
              <i style="color: #9e9e9e" v-else>{{ $t("artworkUntitled") }}</i>
            </h1>
            <div>
              <v-tooltip bottom v-if="!hideActions">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="40px"
                    height="40px"
                    @click="reportProblemDialog = true"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    data-cy="reportProblem"
                  >
                    <v-icon color="secondary">mdi-chat-alert-outline</v-icon>
                  </v-btn>
                </template>
                {{ $t("artworkReportFeedback") }}
              </v-tooltip>
              <v-dialog max-width="500px" v-model="reportProblemDialog" :fullscreen="$screen.width < 600">
                <report-problem :workid="this.displayItem.workid" @close="reportProblemDialog = false"></report-problem>
              </v-dialog>
              <v-btn
                width="40px"
                height="40px"
                icon
                :disabled="blockchainUpdateWait() < 100"
                @click="editWork"
                v-if="owner"
                data-cy="editWork"
                ><img
                  src="@/assets/zeplin/writer.svg"
                  alt=""
                  width="24px"
                  height="24px"
                  v-if="blockchainUpdateWait() >= 100" />
                <v-progress-circular
                  :value="blockchainUpdateWait()"
                  color="primary"
                  size="16"
                  width="2"
                  v-else
                ></v-progress-circular
              ></v-btn>
            </div>
          </div>
          <div class="classificationBox">
            <p style="line-height: normal">{{ this.$t("viewType") }}:</p>
            <p class="sb">{{ classificationStr }}</p>
          </div>

          <div class="timestamp" v-if="$screen.width <= 600">
            <img :src="timestampStickerLink" class="stamp3x" />
            <div class="timestampBarNormal">
              <div class="mb-2">
                <p>{{ $t("viewDate") }}</p>
                <h3>{{ getDate }}</h3>
              </div>
              <div>
                <p>{{ $t("viewTime") }}</p>
                <h3>{{ getTime }}</h3>
              </div>
            </div>
          </div>
          <div class="creatorBox">
            <div class="creator">
              <p style="margin: 0">{{ this.$t("viewCreator") }}:</p>
              &nbsp;
              <div
                @click="goToUserWorks(displayItem.creatorAddress)"
                :style="{ cursor: hideActions ? 'auto' : 'pointer' }"
              >
                <name-tag-from-address
                  :address="displayItem.creatorAddress"
                  textStyle="font-size: 16px; font-weight: 600"
                  :class="hideActions ? null : 'hoverHyperlink'"
                />
              </div>
            </div>
            <div class="creator">
              <p style="margin: 0">{{ this.$t("viewOwner") }}:</p>
              &nbsp;
              <div
                :style="{ cursor: hideActions ? 'auto' : 'pointer' }"
                @click="goToUserWorks(displayItem.ownerAddress)"
              >
                <name-tag-from-address
                  :address="displayItem.ownerAddress"
                  textStyle="font-size: 16px; font-weight: 600"
                  :class="hideActions ? null : 'hoverHyperlink'"
                />
              </div>
            </div>
            <div v-if="displayItem.eventNames && displayItem.eventNames && displayItem.eventNames.length > 0">
              <p style="margin: 0">{{ this.$t("viewEvent") }}</p>
              <div
                v-for="(eventName, i) in displayItem.eventNames"
                :key="i"
                :class="hideActions ? null : 'hoverHyperlink'"
              >
                <h5
                  style="font-weight: 600; font-size: 16px"
                  :style="{ cursor: hideActions ? 'auto' : 'pointer' }"
                  class="pb-1"
                  @click="goToEventBazaar(eventName)"
                >
                  <v-icon size="16px" v-if="!hideActions">mdi-launch</v-icon>
                  <span :class="hideActions ? null : 'ml-2'">{{ eventName }}</span>
                </h5>
              </div>
            </div>

            <div class="likeButton" v-if="$screen.width >= 1264 && !hideActions">
              <p>
                {{
                  $t("viewLike", {
                    likeCount: displayItem.likeCount ? displayItem.likeCount : 0
                  })
                }}
              </p>
              <v-tooltip bottom :disabled="!displayItem.likers || displayItem.likers.length === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="likeArtwork"
                    v-bind="attrs"
                    v-on="on"
                    :loading="likeLoading"
                    data-cy="likeArtwork"
                  >
                    <img src="@/assets/zeplin/like-2.svg" alt="" width="16px" height="16px" />
                  </v-btn>
                </template>
                <div v-if="displayItem.likers">
                  <div v-for="(liker, i) in displayItem.likers" :key="i">{{ liker }}</div>
                </div>
              </v-tooltip>
            </div>
          </div>

          <div class="chips" style="max-height: 500px; width: 100%">
            <div
              class="greenChip"
              :style="{ cursor: hideActions ? 'auto' : 'pointer' }"
              v-for="(hashtag, i) in displayItem.unmanagedInfo.hashTags"
              :key="i"
              @click="hashtagSearch(hashtag)"
              :data-cy="`hashtagSearch-${hashtag}`"
            >
              {{ hashtag }}
            </div>
          </div>

          <div class="likeButton" v-if="$screen.width < 1264 && !hideActions">
            <div style="display: flex; align-items: center">
              <p>
                {{
                  this.$t("viewLike", {
                    likeCount: displayItem.likeCount ? displayItem.likeCount : 0
                  })
                }}
              </p>
              <v-tooltip bottom :disabled="!displayItem.likers || displayItem.likers.length === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="likeArtwork"
                    width="30px"
                    height="30px"
                    :loading="likeLoading"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img src="@/assets/zeplin/like-2.svg" alt="" width="16px" height="16px" />
                  </v-btn>
                </template>
                <div v-if="displayItem.likers">
                  <div v-for="(liker, i) in displayItem.likers.slice(0, 9)" :key="i">{{ liker }}</div>
                </div>
              </v-tooltip>
            </div>
          </div>

          <div
            v-if="displayItem.workFiles.length > 0 || displayItem.coverPhotos.length > 0"
            class="artworkAdditionalFiles"
          >
            <div class="ma-0">
              <p>{{ $t("artworkAdditionalFiles") }}</p>

              <v-tooltip bottom :disabled="allowDownload">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: 100px">
                    <v-menu bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!allowDownload"
                          color="primary"
                          rounded
                          depressed
                          small
                          width="100px"
                          class="my-2"
                          data-cy="artworkDownload"
                          v-bind="attrs"
                          v-on="on"
                          ><b v-if="!downloadLoading">{{ $t("artworkDownload") }}</b>

                          <v-progress-circular
                            v-if="downloadLoading"
                            size="16"
                            width="3"
                            indeterminate
                          ></v-progress-circular
                        ></v-btn>
                      </template>
                      <v-list max-width="50vw">
                        <v-list-item
                          v-for="(item, i) in displayItem.coverPhotos"
                          @click="downloadIndividualFile(item, true)"
                          :key="i"
                          two-line
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t("artworkImage") }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{
                              item.size > 1024 ? Math.round(item.size / 1024) + "MB" : Math.round(item.size) + "KB"
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(item, index) in displayItem.workFiles"
                          :key="displayItem.coverPhotos.length + index"
                          @click="downloadIndividualFile(item, false)"
                          three-line
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t("artworkFile") }}</v-list-item-subtitle>
                            <v-list-item-subtitle
                              >{{
                                item.size > 1024 ? Math.round(item.size / 1024) + "MB" : Math.round(item.size) + "KB"
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item @click="downloadZipFile"
                          ><v-list-item-title style="font-weight: bold">Download all</v-list-item-title></v-list-item
                        > -->
                      </v-list>
                    </v-menu>
                  </div>
                </template>

                <p class="ma-0" v-for="(file, i) in displayItem.coverPhotos.concat(displayItem.workFiles)" :key="i">
                  {{ file.fileName }}
                </p>
              </v-tooltip>
            </div>
          </div>
          <div class="description" v-if="displayItem.unmanagedInfo.description !== ''">
            <p>{{ displayItem.unmanagedInfo.description }}</p>
            <meta name="description" :content="displayItem.unmanagedInfo.description" />
          </div>
          <v-alert text dense :color="statusColor" v-if="owner" data-cy="statusAlert">
            <div>
              {{ $t("artworkStatus") }}
              <span style="font-weight: 600">
                {{ displayItem.workStatus === 2 ? $t("artworkStatusPublic") : $t("artworkStatusPrivate") }}
              </span>
            </div>
          </v-alert>
          <!-- <seller-preference
            :unmanagedInfo="displayItem.unmanagedInfo"
            :submitDisabled="blockchainUpdateWait() < 100"
            :owner="owner"
            :blockchainUpdateWait="blockchainUpdateWait()"
            :workid="displayItem.workid"
            :key="displayItem.workid"
          /> -->
        </div>
        <div class="actionButton" v-if="!hideActions && !hideChatAndPurchase">
          <div v-if="!owner">
            <!-- <div>
              <v-btn
                color="primary"
                rounded
                x-large
                width="160px"
                depressed
                @click="goToChat"
                :disabled="displayItem.workStatus !== 2"
                data-cy="chatPurchase"
              >
                <span style="font-weight: 600; font-size: 12px">
                  {{ $t("artworkChat") }}
                </span>
              </v-btn>
            </div> -->
          </div>

          <div v-if="owner && displayItem.workStatus === 0">
            <v-btn
              color="primary"
              rounded
              x-large
              depressed
              width="160px"
              :disabled="(!submitButton.enabled && !sellerPreferenceChange) || blockchainUpdateWait() < 100"
              @click="upload"
              data-cy="publish"
            >
              <span style="font-weight: 600; font-size: 12px"> {{ submitButton.text }}</span>
              <v-progress-circular
                v-if="blockchainUpdateWait() < 100"
                :value="blockchainUpdateWait()"
                :size="20"
                class="mx-3"
              ></v-progress-circular>
            </v-btn>
          </div>
        </div>
        <!-- <div v-else>
          <v-btn
            color="primary"
            rounded
            x-large
            depressed
            min-width="160px"
            :to="`/platform/artwork/${displayItem.workid}`"
            @click="$emit('closeWindow')"
            data-cy="loginGoToWork"
            v-if="!hideChatAndPurchase"
          >
            <span style="font-weight: 600; font-size: 12px">
              {{ $t("artworkChat") }}
            </span>
          </v-btn>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getUseridFromAddress } from "@/services/authService";
import { publishToBazaar, likeWork } from "@/services/workService";
import { createNewOrder } from "@/services/chatService";
import config from "@/config";
import MessageBox from "@/components/Common/MessageBox";
import ImageFromFileDescriptor from "../../components/Common/ImageFromFileDescriptor.vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { retrieveLastUpdate } from "../../services/workService";
import {
  downloadBase64ImageFromBFS,
  downloadPrivateBase64ImageFromBFS,
  downloadPrivateFileAndSave
} from "../../services/fileService";
import { getDealList } from "../../services/orderService";
import SellerPreference from "./SellerPreference.vue";
import ReportProblem from "../Common/ReportProblem.vue";
import ConfirmDlg from "./ConfirmDlg.vue";
import NameTagFromAddress from "../Common/NameTagFromAddress.vue";

export default {
  components: {
    MessageBox,
    ImageFromFileDescriptor,
    SellerPreference,
    ReportProblem,
    ConfirmDlg,
    NameTagFromAddress
  },
  props: {
    displayItem: Object,
    hideActions: { type: Boolean, default: false },
    hideChatAndPurchase: { type: Boolean, default: false }
  },
  async mounted() {
    this.loadItem();
    this.getNow();
    this.promptWorkPublish();
    setInterval(() => {
      this.getNow();
    }, 1000);
  },
  watch: {
    workid() {
      this.loadItem();
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getNow() {
      this.datenow = new Date().getTime();
    },
    async upload() {
      this.$store.commit("showProgressLine", true);
      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");
      const workid = this.workid;

      if (this.displayItem.workStatus === 0) {
        const payload = {
          toPublish: true,
          publicKey: keypair.publicKey,
          privateKey: keypair.privateKey
        };

        const result = await publishToBazaar(this.$axios, config, token, workid, payload);

        if (result.status === 200) {
          this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid });
          // this.$router.push("/platform/mycollection");
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
        if (this.sellerPreferenceChange) {
          this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid });
        }
      } else if (this.displayItem.workStatus > 0) {
        this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid });
        this.uploadSellerPreference();
      }
      this.$store.commit("showProgressLine", false);
    },
    async likeArtwork() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return;
      }
      const token = this.$session.get("token");
      this.likeLoading = true;
      const payload = {
        like: !this.likedArtwork
      };

      const result = await likeWork(this.$axios, config, token, this.workid, payload);
      if (result.status === 200) {
        this.$emit("refreshItem");
        this.loadItem();
        setTimeout(() => {
          this.likeLoading = false;
        }, 1000);
      } else {
        this.messageBox.content = `${result.data.message}`;
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
        this.likeLoading = false;
      }
    },
    loadItem() {
      const cleanKeyPhotoIndex = (i) => {
        if (!i) {
          return 0;
        }
        if (i < 0) {
          return 0;
        }
        return i;
      };
      this.artworkTitle = this.displayItem.workInfo.artworkTitle;

      if (this.displayItem.unmanagedInfo.sellerPreference) {
        this.sellerPreference = this.displayItem.unmanagedInfo.sellerPreference;
      }

      if (this.displayItem.unmanagedInfo.keyPhoto) {
        this.viewImageIndex = cleanKeyPhotoIndex(this.displayItem.unmanagedInfo.keyPhoto);
      }

      this.getCreatorID();
      this.getOwnerID();
    },
    async downloadAdditionalFile(workFile) {
      this.downloadFile(workFile);
    },
    async downloadFile(elem) {
      const keypair = this.$session.get("keypair");
      let result = { data: null };
      try {
        if (elem.isCoverPhoto) {
          let contentType = this.getContentType(elem);
          result.data = await downloadBase64ImageFromBFS(this.$axios, config, elem, contentType, true);
          return result;
        } else {
          let contentType = this.getContentType(elem);
          if (contentType) {
            result.data = await downloadPrivateBase64ImageFromBFS(this.$axios, config, elem, keypair, contentType);
          } else {
            result = await downloadPrivateFileAndSave(this.$axios, config, elem, keypair);
          }
          return result;
        }

        // var fileURL = window.URL.createObjectURL(new Blob([result.data]));
        // var fileLink = document.createElement("a");

        // fileLink.href = fileURL;
        // fileLink.setAttribute("download", elem.fileName);
        // document.body.appendChild(fileLink);

        // fileLink.click();
      } catch (error) {
        this.messageBox.content = `The additional files cannot be downloaded due to ${error}.`;
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
      }
    },
    getContentType(fileDescriptor) {
      let result;
      switch (fileDescriptor.fileType) {
        case ".jpg":
          result = "image/jpeg";
          return result;
        case ".jpeg":
          result = "image/jpeg";
          return result;
        case ".png":
          result = "image/png";
          return result;
        case ".svg":
          result = "image/svg+xml";
          return result;
        case ".tif":
          result = "image/tiff";
          return result;
        case ".tiff":
          result = "image/tiff";
          return result;
        case ".webp":
          result = "image/webp";
          return result;
        case ".bmp":
          result = "image/bmp";
          return result;
        case ".gif":
          result = "image/gif";
          return result;
        default:
          result = null;
          return result;
      }
    },
    async downloadZipFile() {
      this.downloadLoading = true;
      this.bytesDownloaded = 0;
      this.zipProgress = 0;
      let zip = new JSZip();
      let coverPhoto = this.displayItem.coverPhotos[0];
      coverPhoto.isCoverPhoto = true;
      let fileList = this.displayItem.workFiles.concat([coverPhoto]);
      let downloadPromise = fileList.map((elem) => {
        return this.downloadFile(elem).then((res) => {
          this.bytesDownloaded = this.bytesDownloaded + elem.size;
          return res;
        });
      });

      const result = await Promise.all(downloadPromise);
      result.forEach((file, i) => {
        zip.file(fileList[i].fileName, file.data, { base64: true });
      });
      let workid = this.displayItem.workid;
      let self = this;
      const callbackProgress = (metadata) => {
        self.zipProgress = metadata.percent / 100;
      };
      zip.generateAsync({ type: "blob" }, callbackProgress).then(function (blob) {
        self.downloadLoading = false;
        self.zipProgress = 1;
        saveAs(blob, workid.concat("_files").concat(".zip"));
      });
    },
    async downloadIndividualFile(item, isCoverPhoto) {
      this.downloadLoading = true;

      item.isCoverPhoto = isCoverPhoto;

      await this.downloadFile(item).then((res) => {
        if (isCoverPhoto) {
          item.fileName = item.fileName.replace(/\.[^/.]+$/, "");
        }
        saveAs(res.data, item.fileName);
      });
      this.downloadLoading = false;
    },
    async getCreatorID() {
      const result = await getUseridFromAddress(this.$axios, config, this.displayItem.creatorAddress);
      if (result.status === 200) {
        this.creatorID = result.data.userid;
      }
    },
    async getOwnerID() {
      const result = await getUseridFromAddress(this.$axios, config, this.displayItem.ownerAddress);
      if (result.status === 200) {
        this.ownerID = result.data.userid;
      }
    },
    async goToChat() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return;
      }
      this.$store.commit("showProgressLine", true);

      const token = this.$session.get("token");
      let orderExist, orderInfo;
      await getDealList(this.$axios, config, token, "buyer").then((res) => {
        if (res.status === 200) {
          let dealListBuyer = res.data.data;
          orderExist = dealListBuyer.some((elem) => elem.workid === this.workid && elem.orderStatus === 0);
          orderInfo = dealListBuyer.find((elem) => elem.workid === this.workid && elem.orderStatus === 0);
        } else {
          this.messageBox.content = this.$t(res.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        }
      });

      if (orderExist) {
        this.$router.push({
          name: `Chatroom`,
          params: {
            orderid: orderInfo.orderid
          }
        });
      } else {
        // if (!this.$store.state.userProfile.isPremium) {
        //   this.messageBox.content = this.$t("dialogMsg.chat.upgrade");
        //   this.messageBox.state = true;
        //   this.messageBox.cssClass = "message";
        //   this.messageBox.destination = "/platform/premium";
        //   this.messageBox.allowCancel = true;
        //   this.$store.commit("showProgressLine", false);
        //   return;
        // }
        const result = await createNewOrder(this.$axios, config, token, this.workid);
        if (result.status === 200) {
          const orderid = result.data.data.orderid;
          if (orderid) {
            await this.$store.dispatch("getDealList", {
              axios: this.$axios,
              config: config,
              token: this.$session.get("token")
            });
            this.$router.push({
              name: `Chatroom`,
              params: {
                orderid: orderid
              }
            });
          }
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
      this.$store.commit("showProgressLine", false);
    },
    blockchainUpdateWait() {
      return retrieveLastUpdate(this.displayItem.workid, this.datenow);
    },
    hashtagSearch(hashtag) {
      if (this.hideActions) {
        return;
      }
      this.$router.push({
        path: `/platform/bazaar?hashtag=${hashtag}`
      });
    },
    promptWorkPublish() {
      const promptWorkid = localStorage.getItem("promptWorkPublish");
      if (promptWorkid && promptWorkid === this.workid) {
        setTimeout(async () => {
          try {
            if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.publish"), "")) {
              this.upload();
            }
          } catch (e) {
            (e) => e;
          }
        }, 20000);
        localStorage.removeItem("promptWorkPublish");
      }
    },
    navigateWork(direction) {
      const workList = this.$store.state.workList;
      const index = this.workListIndex;
      let moveToItemWorkid;
      if (direction === "prev") {
        if (index > 0) {
          moveToItemWorkid = workList[index - 1].workid;
        }
      } else {
        if (workList.length > index + 1) {
          moveToItemWorkid = workList[index + 1].workid;
        }
      }
      if (moveToItemWorkid) {
        this.$router.push(`/platform/mycollection/${moveToItemWorkid}`).catch((e) => e);
      }
    },
    moveToPrevWork() {
      if (this.prevWork) {
        this.$router.push(this.prevWork).catch((e) => e);
        this.$store.commit("setTransition", "slide-right");
      }
    },
    moveToNextWork() {
      if (this.nextWork) {
        this.$router.push(this.nextWork).catch((e) => e);
        this.$store.commit("setTransition", "slide-left");
      }
    },
    editWork() {
      if (this.hideActions) {
        return;
      }
      this.$router.push(`/platform/edit/${this.workid}`);
    },
    goToEventBazaar(eventName) {
      if (this.hideActions) {
        return;
      }
      this.$router.push(`/platform/bazaar?eventName=${eventName}`);
    },
    async goToUserWorks(address) {
      if (this.hideActions) {
        return;
      }
      let userid = await getUseridFromAddress(this.$axios, config, address)
        .then((res) => {
          if (res.status === 200) {
            return res.data.userid;
          } else {
            return "";
          }
        })
        .catch(() => {
          return "";
        });
      this.$router.push(`/platform/user/${userid}`);
    }
  },
  data: () => ({
    timestampSticker: 2,
    sellerPreferenceChange: false,
    sellerPreferenceLastUpdate: 0,
    sellerPreference: {
      orderType: null,
      price: null,
      duration: null
    },
    errors: {},
    menu: false,
    successMessage: false,
    coverImageLoading: false,
    coverPhoto: null,
    artworkTitle: null,
    displayImage: null,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    likeLoading: false,
    additionalFile: null,
    creatorID: "",
    ownerID: "",
    datenow: null,
    modalWindow: false,
    downloadLoading: false,
    zipProgress: 0,
    bytesDownloaded: 0,
    reportProblemDialog: false,
    promptPublishDialog: false,
    viewImageIndex: 0
  }),
  computed: {
    isLogin() {
      return !!this.$session.get("token");
    },
    inMyCollection() {
      return this.$route.name === "My Private Work";
    },
    lastUpdateTime() {
      if (this.displayItem.ownerAddress !== this.$store.state.userProfile.address) {
        return 0;
      } else {
        try {
          let current = this.$store.getters.getWorkByID(this.displayItem.workid);
          return current.lastUpdate;
        } catch {
          return 0;
        }
      }
    },
    itemArtworkType() {
      if (Object.hasOwnProperty.call(this.displayItem.unmanagedInfo, "artworkType")) {
        return this.displayItem.unmanagedInfo.artworkType;
      } else if (Object.hasOwnProperty.call(this.displayItem.workInfo, "artworkType")) {
        return this.displayItem.workInfo.artworkType;
      } else {
        return null;
      }
    },
    itemArtworkClass() {
      if (Object.hasOwnProperty.call(this.displayItem.unmanagedInfo, "artworkClass")) {
        return this.displayItem.unmanagedInfo.artworkClass;
      } else if (Object.hasOwnProperty.call(this.displayItem.workInfo, "artworkClass")) {
        return this.displayItem.workInfo.artworkClass;
      } else {
        return null;
      }
    },
    classificationStr() {
      let artworkTypeList = [
        { translation: this.$t("artworkTypeName0"), type: 0 },
        { translation: this.$t("artworkTypeName1"), type: 1 },
        { translation: this.$t("artworkTypeName2"), type: 2 },
        { translation: this.$t("artworkTypeName3"), type: 3 },
        { translation: this.$t("artworkTypeName4"), type: 4 }
      ];
      if (
        (this.itemArtworkType || this.itemArtworkType === 0) &&
        (this.itemArtworkClass || this.itemArtworkClass === 0)
      ) {
        let artworkType = artworkTypeList.find((elem) => {
          return elem.type === this.itemArtworkType;
        });
        let artworkTypeName = "artworkType".concat(this.itemArtworkType);
        let artworkClassObj = this.$t(artworkTypeName);
        let artworkClassArray = Object.keys(artworkClassObj).map((key) => {
          return {
            translation: artworkClassObj[key],
            class: parseInt(key.substring(5, 8))
          };
        });

        return artworkType.translation.concat(" - ").concat(artworkClassArray[this.itemArtworkClass].translation);
      } else {
        if (this.itemArtworkType || this.itemArtworkType === 0) {
          let artworkType = artworkTypeList.find((elem) => {
            return elem.type === this.itemArtworkType;
          }).translation;
          return artworkType;
        } else {
          return "None";
        }
      }
    },
    fullName() {
      return this.$store.state.userProfile.userid;
    },

    timestampStickerLink() {
      if (this.timestampSticker === 1) {
        return require("@/assets/zeplin/stamp-3-x.png");
      }
      if (this.timestampSticker === 2) {
        return require("@/assets/zeplin/group-55.png");
      }
      return require("@/assets/zeplin/group-55.svg");
    },
    submitButton() {
      let submitButton = {
        text: null,
        enabled: false
      };
      switch (this.displayItem.workStatus) {
        case 0:
          submitButton.text = this.$t("viewPublish");
          submitButton.enabled = true;
          break;
        case 1:
          submitButton.text = this.$t("viewPublish");
          submitButton.enabled = false;
          break;
        case 2:
          submitButton.text = this.$t("viewPublish");
          submitButton.enabled = false;
          break;
        case 3:
          submitButton.text = this.$t("viewPublish");
          submitButton.enabled = true;
          break;
        default:
          submitButton.text = this.$t("viewPublish");
      }
      return submitButton;
    },
    getTime() {
      const date = new Date(this.displayItem.createdAt);
      let hours = "0".concat(date.getHours()).slice(-2);
      let minutes = "0".concat(date.getMinutes()).slice(-2);
      let seconds = "0".concat(date.getSeconds()).slice(-2);
      return hours + ":" + minutes + ":" + seconds;
    },
    getDate() {
      const date = new Date(this.displayItem.createdAt);
      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
    },
    owner() {
      if (this.displayItem.ownerAddress === this.$store.state.userProfile.address) {
        return true;
      } else {
        return false;
      }
    },
    allowDownload() {
      if (this.downloadLoading) {
        return false;
      }
      if (this.owner) {
        return true;
      } else {
        if (Object.hasOwnProperty.call(this.displayItem, "licensees")) {
          const isLicensee = this.displayItem.licensees.some((elem) => {
            return elem === this.$store.state.userProfile.address;
          });
          if (isLicensee) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    workid() {
      return this.displayItem.workid;
    },
    noTitle() {
      if (!this.displayItem.workInfo.artworkTitle) {
        return true;
      }
      if (!this.displayItem.workInfo.artworkTitle.replace(/\s/g, "").length) {
        return true;
      }
      return false;
    },
    downloadProgress() {
      if (this.displayItem.workFiles.length === 0) {
        return 0;
      }

      return (this.bytesDownloaded / this.totalFileSize) * 50 + this.zipProgress * 50;
    },
    totalFileSize() {
      let totalSize = this.displayItem.workFiles.reduce((a, b) => {
        return a + b.size;
      }, 0);
      totalSize = totalSize + parseInt(this.displayItem.coverPhoto.size);
      return parseInt(totalSize);
    },
    workList() {
      return this.$store.state.workList;
    },
    workListIndex() {
      const workList = this.$store.state.workList;
      const index = workList.findIndex((elem) => {
        return elem.workid === this.workid;
      });
      return index;
    },
    nextWork() {
      const workid = this.$store.getters.navigateBazaarWork(this.workid, "next");
      if (workid) {
        return `/platform/artwork/${workid}`;
      } else {
        return null;
      }
    },
    prevWork() {
      const workid = this.$store.getters.navigateBazaarWork(this.workid, "prev");
      if (workid) {
        return `/platform/artwork/${workid}`;
      } else {
        return null;
      }
    },
    statusColor() {
      switch (this.displayItem.workStatus) {
        case 0:
          return "#B3A7D3";
        case 1:
          return "#F1CA1B";
        case 2:
          return "#2EB73A";
        case 3:
          return "#B3A7D3";
        default:
          return "#2EB73A";
      }
    },
    likedArtwork() {
      if (!this.displayItem.likers) {
        return 0;
      }
      let profile = this.$store.state.userProfile;
      return this.displayItem.likers.some((elem) => {
        return elem === profile.userid;
      });
    }
  }
};
</script>
