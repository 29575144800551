<template>
  <div class="licenseContainer licenseView" v-if="sellerPreference">
    <message-box
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
      :allowCancel="messageBox.allowCancel"
    />
    <div id="licenseHeading" v-if="!editing">
      <div>
        <h4 class="ma-0">
          {{ this.editing ? $t("viewSellerHeading") : viewSellerType(sellerPreference.orderType) }}
        </h4>
      </div>
      <v-tooltip v-model="show" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="white" small class="ml-2" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
        </template>
        <span>{{ $t("artworkSellerPreferenceInfo") }}</span>
      </v-tooltip>
    </div>
    <div id="license" v-if="!editing && (sellerPreference.price || sellerPreference.duration)">
      <v-row>
        <v-col cols="12" md="6" v-if="sellerPreference.price">
          <p style="margin: 0">
            {{ $t("viewSellerPrice") }}
          </p>
          <h3>
            {{
              $t("viewSellerOfferPrice", {
                price: sellerPreference.price
              })
            }}
          </h3>
        </v-col>
        <v-col v-if="sellerPreference.duration" cols="12" md="6">
          <p style="margin: 0">
            {{ $t("viewSellerLicense") }}
          </p>
          <h3>
            {{
              $t("viewSellerLicenseYear", {
                number: sellerPreference.duration
              })
            }}
          </h3>
        </v-col>
      </v-row>
    </div>
    <div id="license" v-if="editing">
      <div style="padding-bottom: 20px">
        <v-radio-group
          v-model="localSellerPreference.orderType"
          row
          hide-details=""
          @change="changeSellerPreference"
          data-cy="orderType"
        >
          <v-radio :label="viewSellerType('negotiable')" value="negotiable" />
          <v-radio :label="viewSellerType('sell')" value="sell" />
          <v-radio :label="viewSellerType('license')" value="license" />
          <v-radio :label="viewSellerType('notForSale')" value="notForSale" />
        </v-radio-group>
      </div>
      <div v-if="localSellerPreference.orderType === 'sell' || localSellerPreference.orderType === 'license'">
        <v-currency-field v-model="localSellerPreference.price" :outlined="true" prefix="US$" />
      </div>
      <div v-if="localSellerPreference.orderType === 'license'" class="my-3">
        <p class="pa-0 ma-0">
          {{
            $t("viewSellerLicense", {
              number: this.localSellerPreference.duration
            })
          }}
        </p>
        <v-select
          v-model="localSellerPreference.duration"
          :items="numYears"
          item-text="translation"
          item-value="value"
          outlined
          dense
          data-cy="numYears"
        ></v-select>
      </div>
    </div>
    <div v-if="owner && editing" id="licenseEdit">
      <!-- <v-btn rounded depressed color="primary" @click="editing = !editing">{{ $t("collectionEdit") }}</v-btn> -->
      <v-btn
        :disabled="submitDisabled"
        rounded
        depressed
        color="primary"
        @click="confirmSellerPreference"
        data-cy="confirmSellerPreference"
        >{{ $t("profileBasicSave") }}
        <v-progress-circular
          v-if="blockchainUpdateWait < 100"
          :value="blockchainUpdateWait"
          :size="20"
          class="mx-3"
        ></v-progress-circular
      ></v-btn>
      <v-btn rounded depressed color="secondary" @click="editing = false" data-cy="cancelSellerPreferenceEdit">{{
        $t("profileBasicCancel")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import VCurrencyField from "../../components/VCurrencyField.vue";
import config from "../../config";
import { uploadUnmanagedWorkInfo } from "../../services/workService";
import MessageBox from "../Common/MessageBox.vue";
export default {
  props: ["workid", "owner", "submitDisabled", "blockchainUpdateWait", "unmanagedInfo"],
  components: {
    VCurrencyField,
    MessageBox
  },
  data: () => ({
    editing: false,
    show: false,
    sellerPreference: {
      orderType: "negotiable",
      price: null,
      duration: null
    },
    localSellerPreference: {
      orderType: "negotiable",
      price: null,
      duration: null
    },
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),
  mounted() {
    if (this.unmanagedInfo.sellerPreference) {
      this.sellerPreference = this.unmanagedInfo.sellerPreference;
      this.localSellerPreference = this.unmanagedInfo.sellerPreference;
    }
  },

  methods: {
    viewSellerType(type) {
      const result = this.translateArray("viewSellerType");
      switch (type) {
        case "negotiable":
          return result[0];
        case "sell":
          return result[1];
        case "license":
          return result[2];
        case "notForSale":
          return result[3];
        default:
          return result[0];
      }
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },
    changeSellerPreference() {
      // Lock user from sending another seller preference update in 30 seconds
      if (this.localSellerPreference.orderType === "negotiable") {
        this.localSellerPreference.duration = null;
        this.localSellerPreference.price = null;
      }
      if (this.localSellerPreference.orderType === "sell") {
        this.localSellerPreference.duration = null;
      }
      if (this.localSellerPreference.orderType === "notForSale") {
        this.localSellerPreference.duration = null;
        this.localSellerPreference.price = null;
      }
      // this.$emit("update:sellerPreference", this.sellerPreference);
      this.sellerPreferenceChange = true;
    },
    async uploadSellerPreference() {
      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");
      const workid = this.workid;
      const unmanagedInfo = this.unmanagedInfo;
      unmanagedInfo.sellerPreference = this.localSellerPreference;
      const payload = {
        unmanagedInfo: JSON.stringify(unmanagedInfo),
        publicKey: keypair.publicKey,
        privateKey: keypair.privateKey
      };
      const result = await uploadUnmanagedWorkInfo(this.$axios, config, token, workid, payload);

      if (result.status === 200) {
        this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid });
        this.sellerPreference = this.localSellerPreference;
        // if (this.displayItem.workStatus >= 1) {
        //   this.successMessage = true;
        //   this.sellerPreferenceChange = false;
        //   return;
        // }
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        return;
      }
    },
    confirmSellerPreference() {
      this.editing = false;
      this.uploadSellerPreference().then(() => {
        this.localSellerPreference = this.unmanagedInfo.sellerPreference;
      });
    }
  },
  computed: {
    numYears() {
      let years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
      years = years.map((elem) => {
        return {
          translation: this.$t("viewSellerLicenseYear", { number: elem }),
          value: elem
        };
      });
      return years;
    }
  }
};
</script>

<style></style>
