<template>
  <v-text-field
    :outlined="outlined"
    dense
    ref="input"
    v-currency="options"
    :value="formattedValue"
    @change="onChange"
    @input="onInput"
    :label="$t('chatContractPrice')"
    :disabled="disabled"
    :error-messages="errorMessages"
    :prefix="prefix"
    data-cy="currency"
  />
</template>

<script>
export default {
  name: "VCurrencyField",
  props: {
    value: {
      type: Number,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    outlined: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: "US"
    }
  },
  data() {
    return {
      formattedValue: null
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    }
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    }
  }
};
</script>
