<template>
  <span v-if="!loading">{{ userid }}</span>
  <v-skeleton-loader type="text" v-else width="100px"></v-skeleton-loader>
</template>

<script>
import config from "@/config";

import { getUseridFromAddress } from "@/services/authService";
export default {
  props: ["address"],
  data: function () {
    return { userid: "", loading: true };
  },
  async created() {
    if (!this.address) {
      this.userid = "";
      return;
    }
    if (this.address === this.$store.state.userProfile.address) {
      this.loading = false;
      this.userid = this.$store.state.userProfile.userid;
      return;
    }

    let useridFromState = this.findUseridFromState(this.address);

    if (useridFromState) {
      this.userid = useridFromState.userid;
    } else {
      let result = await getUseridFromAddress(this.$axios, config, this.address)
        .then((res) => {
          if (res.status === 200) {
            return res.data.userid;
          } else {
            return "";
          }
        })
        .catch(() => {
          return "";
        });
      this.userid = result;
      this.$store.commit("addUseridAddressPair", {
        userid: result,
        address: this.address
      });
    }

    this.loading = false;
  },
  methods: {
    findUseridFromState(address) {
      const useridAddressPair = this.$store.state.useridAddressPair;
      const result = useridAddressPair.find((elem) => {
        return elem.address === address;
      });
      if (result) {
        return result;
      } else {
        return false;
      }
    }
  }
};
</script>
