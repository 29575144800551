<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <v-dialog v-model="rejectDialog" width="500px">
      <v-card class="pa-5">
        <h3>Reject reason</h3>
        <v-text-field outlined type="text" v-model="rejectReason" />
        <v-btn color="primary" depressed rounded @click="disapproveWork(workid)">Confirm</v-btn>
      </v-card>
    </v-dialog>
    <div id="displayCard">
      <v-card min-width="600px" class="pa-10" v-if="this.works.length > 0">
        <h1>Works (total: {{ this.works.length }})</h1>
        <div class="searchToolbar">
          <v-text-field
            v-model="searchInput"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            rounded
            dense
            outlined
          ></v-text-field>
          <div>
            <v-btn icon @click="initialize">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <!-- <vue-json-to-csv :json-data="works" :labels="csvLabels">
              <v-btn depressed>
                Download CSV
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv> -->
          </div>
        </div>

        <v-data-table
          :headers="headers.filter((header) => header.inTable)"
          :items="works"
          :search="searchInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="10"
          dense
        >
          <template v-slot:item.creatorId="{ item }">
            <user-id-from-address
              :address="item.creatorAddress"
              :key="item.workid.concat('-').concat(item.creatorAddress)"
            />
          </template>
          <template v-slot:item.ownerId="{ item }">
            <user-id-from-address
              :address="item.ownerAddress"
              :key="item.workid.concat('-').concat(item.ownerAddress)"
            />
          </template>
          <template v-slot:item.isPending="{ item }">
            <div
              style="display: flex; flex-direction: row; justify-content: center"
              :key="item.workid.concat('-approve-box')"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="approveWork(item.workid)"
                    icon
                    :disabled="item.workStatus !== 1"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    :key="item.workid.concat('-approve-button')"
                  >
                    <v-icon>mdi-check-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Approve</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="disapproveWorkDialog(item.workid)"
                    icon
                    color="red"
                    :disabled="item.workid !== 1"
                    v-bind="attrs"
                    v-on="on"
                    :key="item.workid.concat('-disapprove-button')"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Disapprove</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.workStatus="{ item }">
            <span>{{ findWorkStatus(item.workStatus) }}</span>
          </template>
          <template v-slot:item.isHidden="{ item }">
            <div
              style="display: flex; flex-direction: row; justify-content: center"
              :key="item.workid.concat('-hide-work-button')"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="hideWork(item.workid)"
                    icon
                    :disabled="item.workStatus !== 2 || item.isHidden"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-eye-off-outline</v-icon>
                  </v-btn>
                </template>
                <span>Hide</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :key="item.workid.concat('-unhide-button')"
                    @click="unhideWork(item.workid)"
                    icon
                    color="secondary"
                    :disabled="item.workStatus !== 2 || !item.isHidden"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>Unhide</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.isLanding="{ item }">
            <div
              style="display: flex; flex-direction: row; justify-content: center"
              :key="item.workid.concat('-landing-button')"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="setAsLanding(item.workid, true)"
                    icon
                    v-if="!item.isLanding"
                    :disabled="item.isHidden || item.workStatus !== 2"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-flag</v-icon>
                  </v-btn>
                </template>
                <span>Set as landing page image</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="setAsLanding(item.workid, false)"
                    icon
                    v-if="item.isLanding"
                    :disabled="item.isHidden || item.workStatus !== 2"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-flag-remove</v-icon>
                  </v-btn>
                </template>
                <span>Remove from landing page</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{
              new Date(item.createdAt).toLocaleString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
              })
            }}
          </template>
          <template v-slot:item.viewInBazaar="{ item }">
            <!-- <v-btn icon>
              <v-icon color="primary" v-if="item.workStatus === 2 && !item.isHidden" @click="goToBazaar(item.workid)">
                mdi-earth
              </v-icon>
            </v-btn> -->
            <v-btn icon @click="item.viewArtwork = true" color="primary">
              <v-icon>mdi-earth</v-icon>
            </v-btn>
            <artwork-display-dialog v-model="item.viewArtwork" :item="item" />
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
// import AddArtwork from "@/components/Platform/AddArtwork";
import PlatformLayout from "@/layouts/Platform";
import { getAllWorks, approveWork, hideWork } from "@/services/adminService";
import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";
// import VueJsonToCsv from "vue-json-to-csv";

import ArtworkDisplayDialog from "@/components/Common/ArtworkDisplayDialog.vue";
import { setAsLanding } from "../../../services/adminService";
import { displayLandingWorks } from "../../../services/workService";
import UserIdFromAddress from "../../../components/Common/userIdFromAddress.vue";

export default {
  name: "AdminWorkFunctions",
  components: {
    MessageBox,
    ArtworkDisplayDialog,
    UserIdFromAddress

    // VueJsonToCsv
  },

  data: () => ({
    files: [],
    expand: false,
    openIdx: -1,
    headers: [
      {
        text: "Open",
        align: "left",
        sortable: true,
        value: "viewInBazaar",
        inTable: true
      },
      {
        text: "Approve/ Disapprove",
        value: "isPending",
        align: "center",
        sortable: true,
        inputField: false,
        inTable: true
      },
      {
        text: "Hide/ Unhide",
        value: "isHidden",
        align: "center",
        sortable: true,
        inputField: false,
        inTable: true
      },
      {
        text: "Landing",
        value: "isLanding",
        align: "center",
        sortable: true,
        inputField: false,
        inTable: true
      },
      {
        text: "Artwork Title",
        align: "left",
        sortable: true,
        value: "workInfo.artworkTitle",
        inTable: true
      },
      {
        text: "Creator",
        align: "left",
        sortable: true,
        value: "creatorId",
        inTable: true
      },
      {
        text: "Owner",
        align: "left",
        sortable: true,
        value: "ownerId",
        inTable: true
      },
      {
        text: "Work ID",
        align: "left",
        sortable: true,
        value: "workid",
        inTable: true
      },
      // {
      //   text: "Creator Address",
      //   align: "left",
      //   sortable: true,
      //   value: "creatorAddress",
      //   inTable: true
      // },
      // {
      //   text: "Owner Address",
      //   align: "left",
      //   sortable: false,
      //   value: "ownerAddress",
      //   inTable: true
      // },
      {
        text: "Work Status",
        align: "left",
        sortable: true,
        value: "workStatus",
        inTable: true
      },
      {
        text: "Deleted",
        align: "left",
        sortable: true,
        value: "isDeleted",
        inTable: true
      },

      {
        text: "Created At",
        align: "left",
        sortable: true,
        value: "createdAt",
        inTable: true
      }
    ],
    sortBy: "createdAt",
    sortDesc: true,
    statusCode: [
      {
        label: "AN",
        roleType: 0,
        color: "#2EB73A"
      },
      {
        label: "Premier Member",
        roleType: 2,
        color: "#B3A7D3"
      },
      {
        label: "Member",
        roleType: 1,
        color: "#F1951B"
      }
    ],
    works: [],
    addrList: {},
    editedIndex: -1,
    timer: null,
    searchField: null,
    searchInput: null,
    addingArtwork: false,
    pageNumber: 1,
    itemsPerPage: 5,
    pageCount: 1,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    dialog: false,
    viewItem: "",
    rejectDialog: false,
    rejectReason: "",
    idAddressPair: {}
  }),
  computed: {
    // role () {
    //   return this.$session.get('role')
    // }
  },
  mounted() {
    this.initialize();
  },
  async created() {
    const role = this.$session.get("role");
    if (role !== "AN") {
      this.$router.push({ name: "Not Found" });
      return;
    }
    this.$emit(`update:layout`, PlatformLayout);

    this.role = this.$session.get("role");
    if (this.works.length === 0 && this.role === "member") {
      this.timer = setInterval(() => {
        this.role = this.$session.get("role");

        if (this.role !== "member") {
          clearInterval(this.timer);
          this.initialize();
        }
      }, 1000);
    } else {
      // to deal with refresh
      clearInterval(this.timer);
      this.initialize();
    }
  },
  updated() {
    // this.role = this.$session.get('role')
  },
  methods: {
    getStatusCode(item) {
      return this.statusCode.find((obj) => obj.roleType === item.roleType);
    },
    getStatusColor(item) {
      let status = this.statusCode.find((obj) => obj.roleType === item.roleType);
      return "color: " + status.color + "; margin: 0;";
    },
    async initialize() {
      this.$store.commit("showProgressLine", true);
      this.works = [];
      //  Get request to server
      const token = this.$session.get("token");
      const result = await getAllWorks(this.$axios, config, token);
      const landing = await displayLandingWorks(this.$axios, config);

      if (result.status === 200) {
        const workPromise = result.data.map(async (elem) => {
          const isLanding = landing.data.some((e) => e.workid === elem.workid);
          if (isLanding) {
            elem.isLanding = true;
          } else {
            elem.isLanding = false;
          }
          elem.isPending = elem.workStatus === 1;
          elem.viewArtwork = false;
          return elem;
        });

        const works = await Promise.all(workPromise).then((res) => {
          return res;
        });

        this.works = works;
      } else {
        this.messageBox = {
          content: `Failed to retrieve user information. Error code: ${result.status}. Error message: ${result.message}.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
      this.$store.commit("showProgressLine", false);
    },
    async approveWork(workid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to approve work ${workid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");

        const payload = keypair;
        payload.toApprove = true;
        this.$store.commit("showProgressLine", true);
        const result = await approveWork(this.$axios, config, workid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `Work approved`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        } else {
          this.messageBox = {
            content: `Failed to approve work. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        }
      }
    },
    disapproveWorkDialog(workid) {
      this.workid = workid;
      this.rejectDialog = true;
    },
    async disapproveWork(workid) {
      this.$store.commit("showProgressLine", true);
      const token = this.$session.get("token");
      const prompt = `Confirm to disapprove work ${workid}?`;
      if (confirm(prompt)) {
        this.rejectDialog = false;
        const keypair = this.$session.get("keypair");
        const payload = keypair;
        payload.toApprove = false;
        payload.rejectReason = this.rejectReason;
        const result = await approveWork(this.$axios, config, workid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `Work disapproved`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 10000);
        } else {
          this.messageBox = {
            content: `Failed to disapprove work. Error code: ${result.status}. Error message: ${result.data}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        }
      }
    },
    async hideWork(workid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to hide work ${workid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");

        const payload = keypair;
        payload.toHide = true;
        this.$store.commit("showProgressLine", true);
        const result = await hideWork(this.$axios, config, workid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `Work hidden`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        } else {
          this.messageBox = {
            content: `Failed to hide work. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        }
      }
    },
    async unhideWork(workid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to unhide work ${workid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");

        const payload = keypair;
        payload.toHide = false;
        this.$store.commit("showProgressLine", true);
        const result = await hideWork(this.$axios, config, workid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `Work unhidden`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        } else {
          this.messageBox = {
            content: `Failed to unhide work. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        }
      }
    },
    async setAsLanding(workid, isLanding) {
      this.$store.commit("showProgressLine", true);
      const token = this.$session.get("token");
      let prompt;
      if (isLanding) {
        prompt = `Confirm to set work ${workid} as landing page image?`;
      } else {
        prompt = `Confirm to remove work ${workid} as landing page image?`;
      }

      if (confirm(prompt)) {
        let payload = {
          isLanding
        };

        const result = await setAsLanding(this.$axios, config, workid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: isLanding ? `Work set as landing` : `Work unset as landing`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 10000);
        } else {
          this.messageBox = {
            content: `Failed to set work as landing. Error code: ${result.status}. Error message: ${result.data}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.works = [];
          setTimeout(async () => {
            await this.initialize();
            this.$store.commit("showProgressLine", false);
          }, 5000);
        }
      }
    },
    goToBazaar(workid) {
      this.$router.push(`/platform/artwork/${workid}`);
    },
    findWorkStatus(workStatus) {
      switch (workStatus) {
        case 0:
          return "Private";
        case 1:
          return "Pending";
        case 2:
          return "On Market";
        case 3:
          return "Rejected";
        default:
          return "Undefined";
      }
    }
  }
};
</script>
