<template>
  <v-dialog v-model="open" scrollable max-width="1000px">
    <v-card>
      <div style="display: flex; justify-content: flex-end; padding: 8px">
        <v-btn depressed icon @click="$emit('input', false)">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div id="displayCard" class="ma-0" style="max-height: 100vh; overflow-y: scroll">
        <div class="addArtwork" style="display: flex; flex-direction: column; align-items: center">
          <artwork-display
            :displayItem="item"
            class="artworkDisplay"
            :hideActions="true"
            style="max-width: 3000px; box-shadow: none"
            @closeWindow="$emit('input', false)"
            :hideChatAndPurchase="hideChatAndPurchase"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ArtworkDisplay from "../Platform/ArtworkDisplay.vue";
export default {
  components: {
    ArtworkDisplay
  },
  props: {
    item: {
      type: Object
    },
    value: {
      type: Boolean
    },
    hideChatAndPurchase: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style></style>
